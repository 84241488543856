import * as React from "react";
import PageLayout from "../../PageLayout";
import { Helmet } from "react-helmet";
import PrevNext from "../../components/PrevNext";
import SectionLayout from "../../SectionLayout";

import background from "../../images/previousprojects/3.jpg"
const FuelTransfer = () => {
    return (
        <PageLayout
            noButton
            image={background}
            navSize={"h-96"}
            title={"Fuel transfer systems"}
            titleStyles={"xl:mt-12 lg:mt-20 md:mt-20 sm:mt-20 xs:mt-20 mt-16"}
        >
            <Helmet>
                <title>
                    Fuel transfer systems | EPG Solutions
                </title>
                <meta
                    name="description"
                    content="Client: Power Water Completed: 2019-20 Location: Ramingining, Goulbourn Island, Alcoota, Umbakumba, Stirling (Wilora) Scope: Installation of the DT240, DT240E, FT12, and FTS415 control systems. DT240 and DT240E are automated fuel transfer systems that pumps fuel from the selected bulk tank to the power station day tank which gravity feeds to the generator fuel tanks ensuring."
                />
            </Helmet>
            <div className={"bg-epg-yellow h-5"} />
            <SectionLayout bgColor={"bg-epg-white"}>
                <div className={" py-5"}>
                    <div className={"px-2 mb-10  lg:leading-8 lg:text-lg"}>
                        <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
                            Client:
                            <span className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}>
              Power Water
            </span>
                        </p>
                        <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
                            Completed:
                            <span className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}>
              2019-20
            </span>
                        </p>
                        <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
                            Location:
                            <span className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}>
              Ramingining, Goulbourn Island, Alcoota, Umbakumba, Stirling
              (Wilora)
            </span>
                        </p>
                        <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
                            Scope:
                            <span className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}>
              Installation of the DT240, DT240E, FT12, and FTS415 control
              systems.
              <br />
              <br />
              DT240 and DT240E are automated fuel transfer systems that pumps
              fuel from the selected bulk tank to the power station day tank
              which gravity feeds to the generator fuel tanks ensuring a
              reliable fuel source during operation.
              <br />
              <br />
              FTS12 & FTS415 are fuel pump control systems that control the
              transfer of fuel from the barge landing holding tank to the power
              station bulk tanks. These systems can be set to deliver fuel at
              any desired quantity over a certain time period with a duel
              shutdown/stop safety system.
            </span>
                        </p>
                        <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
                            Challenges:
                            <span className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}>
              Remote location, Design of custom system
            </span>
                        </p>
                        <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
                            Outcome:
                            <span className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}>
              On time, on budget, happy customer.
            </span>
                        </p>
                    </div>
                    <PrevNext
                        prev={"bore-panel-upgrades/"}
                        next={"peppimenarti-power-station/"}
                    />
                </div>
            </SectionLayout>
        </PageLayout>
    );
};

export default FuelTransfer;
